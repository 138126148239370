<template>
    <div>
        <b-container>
            <section class="interview">
                <b-row>
                    <b-col cols="12" lg="8">
                        <div class="sector">
                            <span class="interviews__date"><span class="agencies__star-tooltip" v-b-tooltip title="Interview date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>{{ interviewDate }}</span>
                            <span class="interviews__person"><span class="agencies__star-tooltip" v-b-tooltip title="Interviewee"><simple-line-icons icon="user" size="small" color="#504e70" /></span>{{ interviewInterviewee }}</span>
                            <div v-html="interviewContent"></div>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="4">
                        <div class="sector">
                            <div class="event-details__element mb-0">
                                <h3 class="event-details__subtitle">Company info</h3>
                                <span class="event-details__info"><simple-line-icons icon="briefcase" size="small" color="#504e70" />{{ interviewCompany }}</span>
                                <span v-if="interviewEmail" class="event-details__info"><simple-line-icons icon="envelope" size="small" color="#504e70" /><a class="event-details__link" :href="'mailto:'+interviewEmail">{{interviewEmail}}</a></span>
                                <span v-if="interviewPhone" class="event-details__info"><simple-line-icons icon="phone" size="small" color="#504e70" />{{interviewPhone}}</span>
                                <span v-if="interviewWebsite" class="event-details__info"><simple-line-icons icon="globe" size="small" color="#504e70" /><a class="event-details__link" :href="interviewWebsite" target="blank">website</a></span>
                                <span v-if="interviewLocation" class="event-details__info"><simple-line-icons icon="locationPin" size="small" color="#504e70" />{{interviewLocation}}</span>
                            </div>
                        </div>
                        <div class="sector pb-0">
                            <div class="event-details__element">
                                <h3 class="event-details__subtitle">Other interviews</h3>
                                <div v-for="interview in getLatestInterviews" :key="interview.id" class="interviews__element interviews__element--full">
                                    <div class="interviews__content">
                                        <span class="interviews__date"><span class="agencies__star-tooltip" v-b-tooltip title="Interview date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>{{ interview.date }}</span>
                                        <span class="interviews__person"><span class="agencies__star-tooltip" v-b-tooltip title="Interviewee"><simple-line-icons icon="user" size="small" color="#504e70" /></span>{{ interview.interviewee }}</span>
                                        <p class="interviews__company">{{ interview.company }}</p>
                                        <h2 class="interviews__title interviews__title--full">{{ interview.title }}</h2>
                                    </div>
                                    <div class="events__details">
                                        <button @click="redirectToLatest(interview.id)" class="details-button"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Read more</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import router from '../router/router'
export default {
  components: {
    SimpleLineIcons
  },
  data: function () {
    return {
      screenWidth: 0,
      desktopMode: false,
      interviewContent: '',
      interviewDate: '',
      interviewInterviewee: '',
      interviewCompany: '',
      interviewEmail: '',
      interviewPhone: '',
      interviewWebsite: '',
      interviewWebsiteUrl: '',
      interviewLocation: ''
    }
  },
  created () {
    try {
      var data = require('../assets/json/' + router.currentRoute.params.id + '.json')

      this.interviewContent = data.interview.content
      this.interviewDate = data.interview.date
      this.interviewInterviewee = data.interview.interviewee
      this.interviewCompany = data.interview.company
      this.interviewEmail = data.interview.email
      this.interviewPhone = data.interview.phone
      this.interviewWebsite = data.interview.website
      this.interviewLocation = data.interview.location
    } catch (ex) {
      router.push('/interviews')
    }
  },
  methods: {
    redirectToLatest (routeId) {
      var data = require('../assets/json/' + routeId + '.json')

      this.interviewContent = data.interview.content
      this.interviewDate = data.interview.date
      this.interviewInterviewee = data.interview.interviewee
      this.interviewCompany = data.interview.company
      this.interviewEmail = data.interview.email
      this.interviewPhone = data.interview.phone
      this.interviewWebsite = data.interview.website
      this.interviewLocation = data.interview.location

      router.push({ name: 'interview', params: { id: routeId } })
    }
  },
  computed: {
    routeId: function () {
      return this.$route.params.id
    },
    getLatestInterviews () {
      var data = require('../assets/json/interviews.json')
      var latest = Object.values(data).reverse().slice(0, 4).filter((interview) => {
        return !interview.id.includes(this.$route.params.id)
      })

      return latest
    }
  }
}
</script>
